export const MAX_TICKET_INPUT = 10;

enum EnvName {
  "local" = "local",
  "staging" = "staging",
  "production" = "production",
}

const networksFromEnvName: {
  [key in EnvName]: "ganache" | "mumbai" | "polygon";
} = {
  local: "ganache",
  staging: "mumbai",
  production: "polygon",
};

const networks = {
  ganache: {
    chainId: 1337,
    networkId: 5777,
    name: "ganache",
    chainName: "Ganache",
    displayName: "Ganache local",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ["HTTP://127.0.0.1:7545"],
    blockExplorerUrls: ["https://etherscan.io/"],
    helpLinkSetup:
      "https://dapp-world.com/blogs/01/how-to-connect-ganache-with-metamask-and-deploy-smart-contracts-on-remix-without-1619847868947#:~:text=Connection%20of%20Ganache%20with%20Metamask%20%3A&text=Open%20Metamask%20and%20go%20to,ID%20for%20ganache%20is%201337",
  },
  mumbai: {
    chainId: 80001,
    networkId: 80001,
    name: "mumbai",
    chainName: "Mumbai",
    displayName: "Mumbai - Polygon testnet",
    nativeCurrency: {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18,
    },
    rpcUrls: ["https://rpc-mumbai.maticvigil.com"],
    blockExplorerUrls: ["https://mumbai.polygonscan.com/"],
    helpLinkSetup:
      "https://docs.unstoppabledomains.com/manage-domains/guides/add-polygon-to-metamask/",
  },
  polygon: {
    chainId: 137,
    networkId: 137,
    name: "polygon",
    chainName: "Polygon Mainnet",
    displayName: "Polygon",
    nativeCurrency: {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18,
    },
    rpcUrls: ["https://polygon-rpc.com/"],
    blockExplorerUrls: ["https://polygonscan.com/"],
    helpLinkSetup:
      "https://wiki.polygon.technology/docs/develop/metamask/config-polygon-on-metamask/",
  },
};

export const lotteriesInfos = {
  ganache: {
    ticketPrice: "1",
    currency: "ETH",
  },
  mumbai: {
    ticketPrice: "1",
    currency: "MATIC",
  },
  polygon: {
    ticketPrice: "1",
    currency: "MATIC",
  },
};

/**
 * Env
 */
export const ENV_NAME = process.env.REACT_APP_ENV_NAME as EnvName;

/**
 * Networks
 */
export const NETWORK_NAME = networksFromEnvName[ENV_NAME];
export const NETWORK = networks[NETWORK_NAME];

/**
 * Lottery
 */
export const LOTTERY_INFOS = lotteriesInfos[NETWORK_NAME];
