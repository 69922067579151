import { EuroCircleOutlined, UserAddOutlined } from "@ant-design/icons";
import { Button, Input, Modal, Row } from "antd";
import { useCallback, useState } from "react";
import { MAX_TICKET_INPUT } from "../../config/config";
import style from "./LotteryActions.module.scss";

type Props = {
  participate: (ticketCount: number) => void;
  chooseWinner: () => void;
  isParticipationLoading: boolean;
  isChoosingWinnerLoading: boolean;
};

export default function LotteryActions({
  participate,
  chooseWinner,
  isParticipationLoading,
  isChoosingWinnerLoading,
}: Props) {
  const [ticketCount, setTickerCount] = useState(1);

  const onParticipate = useCallback(() => {
    if (ticketCount > MAX_TICKET_INPUT) {
      setTickerCount(MAX_TICKET_INPUT);
      Modal.info({
        content: `${MAX_TICKET_INPUT} max.`,
      });
    } else {
      participate(ticketCount);
      setTickerCount(1);
    }
  }, [participate, ticketCount]);

  return (
    <div className={style.root}>
      <div>
        <h1 className="title">Try your luck</h1>
        <Row
          align="middle"
          style={{ flexFlow: "unset", justifyContent: "space-between" }}
        >
          <Input
            value={ticketCount}
            onChange={(e) => setTickerCount(Number(e.target.value))}
            type="number"
            max={MAX_TICKET_INPUT}
          />
          <Button
            className="btnAction btnParticipate"
            onClick={onParticipate}
            icon={<UserAddOutlined />}
            type="primary"
            loading={isParticipationLoading}
            disabled={isChoosingWinnerLoading}
          >
            Participate
          </Button>
        </Row>
      </div>
      <div>
        <h1 className="title">Choose a winner</h1>
        <Button
          className="btnAction btnDrawLottery"
          onClick={chooseWinner}
          icon={<EuroCircleOutlined />}
          type="primary"
          loading={isChoosingWinnerLoading}
        >
          Draw Lottery
        </Button>
      </div>
    </div>
  );
}
