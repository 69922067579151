import { useCallback, useEffect, useState } from "react";

export default function useAccount() {
  const [accountAddress, setAccountAddress] = useState("");

  const loadAccount = useCallback(async () => {
    if (!window.ethereum) {
      return;
    }
    const accounts = await window.web3.eth.getAccounts();
    setAccountAddress(accounts[0]);
  }, []);

  const onAccountsChanged = useCallback(async (accounts: string[]) => {
    setAccountAddress(accounts[0]);
  }, []);

  useEffect(() => {
    if (!window.ethereum) {
      return;
    }
    window.ethereum.on("accountsChanged", onAccountsChanged);
  }, [onAccountsChanged]);

  return {
    accountAddress,
    loadAccount,
  };
}
