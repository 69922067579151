import { useEffect } from "react";
import { UserOutlined } from "@ant-design/icons";
import { Card } from "antd";
import style from "./Players.module.scss";

type Props = {
  players: string[];
  getPlayers: () => void;
};

export default function Players({ players, getPlayers }: Props) {
  // Fetch players on mount
  useEffect(() => {
    getPlayers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={style.root}>
      {!players.length && <p className={style.legend}>No players yet</p>}
      {players.map((p, i) => {
        return (
          <Card key={i} className={style.card}>
            <UserOutlined />
            <p className={style.playerAddress}>{p.toString()}</p>
          </Card>
        );
      })}
    </div>
  );
}
