import { NETWORK_NAME } from "../config/config";

type PolyGonGasStation = {
  safeLow: {
    maxPriorityFee: number;
    maxFee: number;
  };
  standard: {
    maxPriorityFee: number;
    maxFee: number;
  };
  fast: {
    maxPriorityFee: number;
    maxFee: number;
  };
  estimatedBaseFee: number;
  blockTime: number;
  blockNumber: number;
};

export const getTxGasData = async () => {
  if (NETWORK_NAME === "ganache") {
    return {};
  }

  const gasstationURL =
    NETWORK_NAME === "mumbai"
      ? "https://gasstation-mumbai.matic.today/v2"
      : "https://gasstation-mainnet.matic.network/v2";

  const polygonGasStationResponse = await fetch(gasstationURL);

  console.log("gasstationURL", gasstationURL);

  const polygonGasStation =
    (await polygonGasStationResponse.json()) as PolyGonGasStation;
  const {
    fast: { maxFee, maxPriorityFee },
  } = polygonGasStation;
  console.log("polygonGasStation", polygonGasStation);

  const maxFeeInWei = Math.ceil(maxFee) * 1e9;
  const maxPriorityFeeInWei = Math.ceil(maxPriorityFee) * 1e9;
  console.log("maxFeeInWei", maxFeeInWei);
  console.log("maxPriorityFeeInWei", maxPriorityFeeInWei);

  return {
    gasPrice: maxFeeInWei,
  };
};
