import { Badge, Row } from "antd";
import { LOTTERY_INFOS } from "../../config/config";
import style from "./LotteryCard.module.scss";

type Props = {
  lotteryBalance: string;
  lotteryName: string;
  playerLotteryBalance: string;
};

export default function LotteryCard({
  lotteryBalance,
  lotteryName,
  playerLotteryBalance,
}: Props) {
  return (
    <div className={style.card}>
      <div className={style.row}>
        <div>
          <p className={style.subtitle}>
            <strong>{lotteryName}</strong>
          </p>
          <Row align="middle">
            <Badge count={`${lotteryBalance} ${LOTTERY_INFOS.currency}`} />
          </Row>
        </div>
      </div>
      {playerLotteryBalance && (
        <p className={style.yourBet}>Your bet : {playerLotteryBalance}</p>
      )}
    </div>
  );
}
