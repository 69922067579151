import { Alert } from "antd";
import style from "./Results.module.scss";

type Props = {
  winner: string;
  accountAddress: string;
};

export default function Results({ accountAddress, winner }: Props) {
  const isWinner = winner?.toLowerCase() === accountAddress?.toLowerCase();

  return (
    <div className={style.root}>
      {isWinner ? (
        <Alert
          message="You won the lottery ! Your account has been credited 💰💰💰"
          type="success"
        />
      ) : (
        <Alert message="You loose.." type="error" />
      )}
      <br />
      <p style={{ fontSize: "14px", marginLeft: "4px" }}>
        Winner is : {winner}
      </p>
    </div>
  );
}
