import { Card } from "antd";
import style from "./Tickets.module.scss";
import { Tickets as TicketsType } from "../../types/tickets";

type Props = {
  tickets: TicketsType;
};

export default function Tickets({ tickets }: Props) {
  if (!tickets.length) {
    return null;
  }

  return (
    <div className={style.root}>
      <h1 className="title">
        Your NTFs tickets{" "}
        <span style={{ fontSize: "16px" }}>({tickets.length})</span>
      </h1>
      <div className={style.cards}>
        {tickets.map((ticket, i) => {
          return (
            <Card key={i} className={style.card}>
              <p className={style.label}>{ticket.id}</p>
              {/* <img src={ticket.imageUrl} alt="" /> */}
            </Card>
          );
        })}
      </div>
    </div>
  );
}
